<template>
  <button class="button-square" @click="$emit('click', $event)">
    <plus-icon v-show="increment" size="20"></plus-icon>
    <minus-icon v-show="decrement" size="20"></minus-icon>
  </button>
</template>

<script>

import { PlusIcon, MinusIcon } from 'vue-feather-icons';

export default {
  name: 'ButtonSquare',
  components: { PlusIcon, MinusIcon },

  props: {
    increment: {
      type: Boolean,
    },
    decrement: {
      type: Boolean,
    },
  },

  data: function () {
    return {

    };
  },

  computed: {

  },

  watch: {

  },

  created: function () {

  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {

  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .button-square {
    @apply flex items-center justify-center bg-dark rounded-3 w-40 h-40 border-2 border-dark text-white;
    &:disabled {
      @apply bg-grayLight border-grayBorder text-gray;
    }
  }
</style>
