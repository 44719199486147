<template>
  <div class="mb-24">
    <label class="input-label text-14 text-dark font-medium mb-8 block"
           :class="[ {'invalid': invalid }]"
           :for="name"
    >
      {{ label }} <span class="text-gray">{{ optional ? $t('Input.optional') : '' }}</span>
    </label>
    <input :id="name"
           v-model="internalValue"
           :type="type"
           :name="name"
           :placeholder="placeholder"
           class="input focus:border-primary active:border-primary appearance-none focus:outline-none"
           :class="[ {'invalid': invalid }]"
    >
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import InputMixin from '@/mixins/InputMixin';
import ErrorMessage from '@/components/lib/InputElements/ErrorMessage';

export default {
  components: { ErrorMessage },
  mixins: [InputMixin],
  props: {
    value: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
  },

  data: function () {
    return {
      internalValue: null,
    };
  },

  computed: {},

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
  },

  created: function () {
    this.internalValue = this.value;
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
