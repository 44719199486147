<template>
  <div>
    <div :class="['room mb-40 select-none', {'pt-40 border-t border-grayLight' : index > 1}]">
      <div class="description mb-32">
        <div class="grid grid-cols-roomCount">
          <h1 class="h1 mb-8">
            {{ $t('roomCountView.nthRoom', [index]) }}
          </h1>
          <div v-if="canDelete"
               class="grid grid-cols-roomCount rounded-full px-16 py-8 leading-16 text-14 border-2 border-grayLight items-center justify-center cursor-pointer relative -top-8"
               @click="$emit('delete')"
          >
            <span class="pr-12">{{ $t('roomCountView.remove') }}</span><x-icon size="1x" class="text-dark"></x-icon>
          </div>
        </div>
        <div class="body">
          <p>{{ $t('roomCountView.howManyGuests') }}</p>
        </div>
      </div>

      <div class="flex justify-between items-center mb-24">
        <div class="text-body font-semibold">
          {{ adultAc.name[locale] }}
        </div>
        <div class="flex">
          <button-square decrement :disabled="internalNumAdults <= 0" @click="changeNumAdults(-1)" />
          <div class="count w-44 flex items-center justify-center font-semibold">
            {{ internalNumAdults }}
          </div>
          <button-square increment :disabled="currentOccupancy >= maxOccupancyAdults" @click="changeNumAdults(1)" />
        </div>
      </div>

      <template v-for="ac in ageCategories">
        <template v-if="ac.ageQualifier !== 'Adult'">
          <div class="flex justify-between items-center mb-24">
            <div class="text-body font-semibold">
              <div>{{ ac.name[locale] }}</div>
              <div class="text-gray text-12 mt-4 pr-8 leading-relaxed font-normal">
                {{ $t('roomCountView.ageCategoryDescription', [ac.ageFrom, ac.ageUntil]) }}
              </div>
            </div>
            <div class="flex">
              <button-square decrement :disabled="internalChildAgeCategories.filter(it => it === ac.id).length <= 0" @click="removeChild(ac.id)" />
              <div class="count w-44 flex items-center justify-center font-semibold">
                {{ internalChildAgeCategories.filter(it => it === ac.id).length }}
              </div>
              <button-square increment :disabled="currentOccupancy >= maxOccupancy" @click="addChild(ac.id)" />
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { PlusIcon, MinusIcon, XIcon } from 'vue-feather-icons';
import ButtonSquare from '../ButtonSquare/ButtonSquare';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { find, sortBy } from 'lodash/collection';
import { max } from 'lodash/math';

export default {
  name: 'RoomCount',
  components: {
    'button-square': ButtonSquare,
    XIcon,
  },

  mixins: [BookingEngineMixin],

  props: {
    index: {
      type: Number,
      default: 1,
      required: true,
    },
    numAdults: {
      type: Number,
      default: 1,
      required: true,
    },
    childAgeCategories: {
      type: Array,
      default: () => [],
      required: true,
    },
    maxOccupancy: {
      type: Number,
      default: 2,
      required: true,
    },
    maxOccupancyAdults: {
      type: Number,
      default: 2,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: function () {
    return {
      internalNumAdults: this.numAdults,
      internalChildAgeCategories: this.childAgeCategories,
    };
  },

  computed: {
    adultAc() {
      return find(this.hotel.ageCategories, (it) => it.ageQualifier === 'Adult');
    },
    ageCategories() {
      return sortBy(this.hotel.ageCategories, 'ageUntil').reverse();
    },
    currentOccupancy() {
      return this.internalNumAdults + this.childAgeCategories.length;
    },
  },

  watch: {
    numAdults: function () {
      this.internalNumAdults = this.numAdults;
      this.internalChildAgeCategories = this.childAgeCategories;
    },
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    changeNumAdults(offset) {
      this.internalNumAdults += offset;
      this.$emit('update:numAdults', this.internalNumAdults);
      this.$emit('change');
    },
    addChild(acId) {
      this.internalChildAgeCategories.push(acId);
      this.$emit('update:childAgeCategories', this.internalChildAgeCategories);
      this.$emit('change');
    },
    removeChild(acId) {
      const index = this.internalChildAgeCategories.indexOf(acId);
      if (index >= 0) {
        this.internalChildAgeCategories.splice(index, 1);
      }
      this.$emit('update:childAgeCategories', this.internalChildAgeCategories);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

</style>
