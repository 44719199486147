// define a mixin object

export default {
  props: {
    name: String,
    label: String,
    optional: Boolean,
    invalid: Boolean,
    validation: Object,
  },

};
