<template>
  <column1 v-if="!isNil(hotel)">
    <info-box />
    <div class="container mx-auto">
      <collapse-transition group>
        <template v-for="(roomRequest, idx) in roomRequests">
          <room-count :key="roomRequest.id"
                      :index="idx + 1"
                      :can-delete="roomRequests.length > 1"
                      :max-occupancy="getMaxOccupancy(roomRequest.id)"
                      :max-occupancy-adults="getMaxOccupancyAdults(roomRequest.id)"
                      :num-adults.sync="roomRequest.numAdults"
                      :child-age-categories.sync="roomRequest.childAgeCategories"
                      @delete="onDelete(roomRequest)"
          />
        </template>
        <button v-if="roomRequests.length < maxRoomsAvailable"
                key="button"
                class="add-room flex items-center py-20 text-primary border-b border-t border-grayLight font-semibold w-full"
                :aria-label="$t('roomCountView.addRoom')"
                @click="onClickAddRoom"
        >
          <plus-icon size="18" class="mr-20 ml-5"></plus-icon>
          {{ $t('roomCountView.addRoom') }}
        </button>
        <input-text v-if="allowDiscountCodes"
                    key="discountCode"
                    v-model="discountCodeEntry"
                    type="discountCode"
                    name="discountCode"
                    :label="$t('roomCountView.discountCode')"
                    :placeholder="$t('roomCountview.discountCodePlaceholder')"
                    class="mt-32"
                    optional
        ></input-text>
      </collapse-transition>

      <button-floating v-if="isQuoting" disabled>
        <i class="text-white fas fa-circle-notch fa-spin fa-2x"></i>&nbsp;<span class="ml-16">{{ $t('loading.loading') }}</span>
      </button-floating>
      <button-floating v-else :disabled="roomRequests.length === 0" @click="onClickNext">
        {{ $tc('roomCountView.next', roomRequests.length, [roomRequests.length]) }}
      </button-floating>
    </div>
  </column1>
</template>

<script>
import { PlusIcon, MoreHorizontalIcon } from 'vue-feather-icons';
import InfoBox from '@/components/lib/InfoBox/InfoBox';
import RoomCount from '@/components/lib/RoomCount/RoomCount';
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';
import { cloneDeep, isEmpty, isNil } from 'lodash/lang';
import { find, groupBy, keyBy, map, size } from 'lodash/collection';
import { head, last, takeRight } from 'lodash/array';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { max, maxBy, min, minBy } from 'lodash/math';
import { CollapseTransition } from 'vue2-transitions';
import shortid from 'shortid';
import TimerMixin from '@/mixins/TimerMixin';
import InputText from '@/components/lib/InputElements/InputText/InputText.vue';

export default {
  name: 'RoomCountView',

  components: {
    'input-text': InputText,
    Column1,
    'info-box': InfoBox,
    'room-count': RoomCount,
    'button-floating': ButtonFloating,
    PlusIcon,
    MoreHorizontalIcon,
    CollapseTransition,
  },

  mixins: [BookingEngineMixin, TimerMixin],

  data: function () {
    return {
      discountCodeEntry: null,
    };
  },

  computed: {
    roomRequests: {
      get: function () {
        return this.$store.getters.roomRequests;
      },
      set: function (newVal) {
        this.$store.dispatch('roomRequests', newVal);
      },
    },
    maxOccupancy() {
      if (isNil(this.roomTypeAvailability)) return max(this.hotel.roomTypes.map((it) => it.maxOccupancy));
      return maxBy(this.roomTypeAvailability, 'maxOccupancy').maxOccupancy;
    },
    minOccupancy() {
      if (isNil(this.roomTypeAvailability)) return min(this.hotel.roomTypes.map((it) => it.minOccupancy));
      return minBy(this.roomTypeAvailability, 'minOccupancy').minOccupancy;
    },
    maxRoomsAvailable() {
      return this.$store.getters.maxRoomsAvailable;
    },
    roomTypeMap() {
      return keyBy(this.hotel.roomTypes, 'id');
    },
    availabilityByMaxOccupancy() {
      return this.$store.getters.availabilityByMaxOccupancy;
    },
    enhancedRoomRequests() {
      return this.$store.getters.enhancedRoomRequests;
    },
    enhancedRoomRequestMap() {
      return keyBy(this.$store.getters.enhancedRoomRequests, 'id');
    },
  },

  watch: {
    'discountCodeEntry': function (newVal) {
      this.$store.commit('discountCode', newVal);
    },
    roomRequests: {
      handler: function (newVal) {
        this.$store.dispatch('roomRequests', newVal);
      },
      deep: true,
    },
    maxRoomsAvailable: function (newVal) {
      if (newVal === 0) {
        this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgNoRoomsAvailable'));
      }
    },
  },

  created() {
    this.discountCodeEntry = this.$store.getters.discountCode || null;
    if (this.roomRequests.length === 0) {
      this.addRoom();
    }
  },

  methods: {
    onClickAddRoom() {
      this.addRoom();
    },
    addRoom() {
      if (this.roomRequests.length < this.maxRoomsAvailable) {
        this.$store.dispatch('addRoomRequest', {
          // id: `R${this.roomRequests.length}`,
          id: shortid.generate(),
          numAdults: this.minOccupancy,
          childAgeCategories: [],
        });
      }
    },
    onDelete(roomRequest) {
      this.$store.dispatch('removeRoomRequest', roomRequest);
    },
    onClickNext() {
      const roomRequestId = this.roomRequests[0].id;
      const roomRequest = find(this.$store.getters.roomRequests, (it) => it.id === roomRequestId);

      this.quote();
      this.$nextTick(() => this.waitForQuote(this._next));
    },
    waitForQuote(fn) {
      return this.setTimeout(() => {
        if (this.isQuoting) {
          this.waitForQuote(fn);
        } else {
          fn();
        }
      }, 500);
    },
    _next() {
      if (isEmpty(this.roomRequests)) this.$rollbar.warning('RoomCountView - onClickNext() - roomRequests isEmpty == true', this.roomRequests);
      const roomRequestId = this.roomRequests[0].id;

      window.scroll({ top: 0, behavior: 'smooth' });
      this.$router.push({ name: 'roomselect', params: { roomRequestId: roomRequestId } });
    },
    getMaxOccupancy(roomRequestId) {
      if (isEmpty(this.enhancedRoomRequestMap) || isEmpty(this.enhancedRoomRequestMap[roomRequestId])) {
        return this.maxOccupancy;
      } else {
        return this.enhancedRoomRequestMap[roomRequestId].maxOccupancy;
      }
    },
    getMaxOccupancyAdults(roomRequestId) {
      return Math.min(this.getMaxOccupancy(roomRequestId), max(this.hotel.roomTypes.map((it) => it.maxOccupancyAdults || it.maxOccupancy)));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
